// eslint-disable-next-line no-redeclare,no-unused-vars
function dataNew(param) {
  var object = param.object;
  if (!parameter[object]) parameter[object] = {};
  if (!fields[object]) fields[object] = {};
  var where = getParamSettings(param, 'where');
  var row = {};
  if (param.new) row = param.new;
  var skip = [];

  if (param.new)
    $.each(param.new, function (key) {
      var settings = getFieldSettings(param, key);
      if (settings.calculate)
        $.each(settings.calculate, function (key2) {
          skip.push(key2);
        });
    });

  $.each(fields[object], function (key) {
    if (row[key]) return;
    var settings = getFieldSettings(param, key);
    var value = null;
    if (param.adopt && param.adopt[key]) {
      var refKey = param.adopt[key];
      var refSettings = getObjectValue(fields, [param.refObj, refKey]);
      value = param.adopt[key];

      if (isNumber(value)) {
        row[key] = value;
        return;
      }

      // value condition
      if (param.ref && isPlainObject(value))
        $.each(value, function (val, cond) {
          if (checkWhere(param.ref, cond)) {
            value = val;
            return false;
          }
        });

      // calculate value
      if (
        isString(value) &&
        (inArray(value, calcVars) ||
          value.match('(' + calcCommands + '):') ||
          str_contains(value, '*') ||
          str_contains(value, '+'))
      )
        value = calculate(value, settings, param.ref, {object: param.refObj});
      if (refKey && refSettings && param.ref && param.refKey != refKey) {
        if (param.ref[refKey] == null) return;
        value = param.ref[refKey];
      }
      if (value == 'new') return;
      if (value == 'object') value = param.refObj;
      else if (value == 'objectSub') value = param.refObjSub;
      else if (value && str_contains(value, '__'))
        value = convertPattern({
          text: value,
          row: param.ref,
          object: param.refObj,
          key: key,
          update: {object: object, key: key, id: 'new'},
          noPdfValue: true,
        });
      else if (
        settings.reference &&
        settings.amount &&
        !isArray(value) &&
        (Number.isInteger(value) || strpos(value, 'x') === false)
      )
        value = '1x' + value;
      else if (
        value &&
        refSettings &&
        refSettings.value &&
        refSettings.type != settings.type
      )
        value = convertPattern({
          text: refSettings.value,
          row: param.ref,
          object: param.refObj,
          key: refKey,
          raw: true,
        });
      else if (str_starts_with(value, "'") && str_ends_with(value, "'"))
        value = value.slice(1, -1);
      else if (str_contains(value, '.'))
        value = calculate(value, settings, param.ref, {
          object: param.refObj,
          objectSub: param.refObjSub,
        });
      if (param.adoptVal && param.adoptVal[value])
        value = param.adoptVal[value];
    } else if (
      where &&
      isset(where[key]) &&
      !settings.sum &&
      !settings.noWhereDefault
    ) {
      if (isString(where[key])) value = calculate(where[key], settings);
      else if (!isPlainObject(where[key]) && !isArray(where[key]))
        value = where[key];
      if (value == '*' || str_starts_with(value, '!')) value = null;
      if (isString(value)) value = value.replace(/[<=>]/g, '');
    }
    if (
      !value &&
      isset(settings.default) &&
      ((settings.defaultCond &&
        checkWhere(row, settings.defaultCond, {nullAsEmpty: true})) ||
        !settings.defaultCond)
    ) {
      if (isNumeric(settings.default) || isArray(settings.default))
        value = settings.default;
      else if (
        (inArray(settings.default, calcVars) ||
          settings.default.match('^(' + calcCommands + '):') ||
          settings.default.match(/(user|store)\./) ||
          str_starts_with(settings.default, 'store.') ||
          str_starts_with(settings.default, 'user.')) &&
        !str_contains(settings.default, '|')
      )
        value = calculate(settings.default, settings, row, {
          object: object,
          key: key,
        });
      else if (
        settings.default &&
        settings.values &&
        inArray(settings.default, settings.values)
      )
        value = settings.default;
      else if (
        settings.default.match('^[a-zA-Z0-9]+$') &&
        !fields[object][settings.default] &&
        !inArray(settings.default, calcVars2)
      )
        value = settings.default;
      else if (
        isString(settings.default) &&
        str_starts_with(settings.default, "'") &&
        str_ends_with(settings.default, "'")
      )
        value = settings.default.substr(1, settings.default.length - 2);
    } else if (!value && settings.defaultFrontend)
      value = settings.defaultFrontend;

    if (isFunction(value)) row[key] = value;
    else if (value != null) {
      if (settings.array && !isArray(value)) value = [value];
      else if (settings.decimal) value = parseFloat(value);
      else if (settings.number) value = parseInt(value);
      else if (settings.date && settings.type == 'date' && value.length > 10)
        value = value.slice(0, 10);
      row[key] = value;
    }
  });

  row.id = 'new';
  row.short = 'Neuer Eintrag';
  if (!data[object]) data[object] = {};
  data[object]['new'] = row;

  return row;
}
